import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actions, actionTypes } from '../redux/actions';
import { WebSiteManagementClient } from '@azure/arm-appservice';
import { InteractiveBrowserCredential } from '@azure/identity';
import { ResourceManagementClient } from '@azure/arm-resources';
import AppTypeEnum from '../utils/appTypeEnum.js';
import dotenv from 'dotenv';
import { getCachedAsync } from '../utils/localCache';
import { getBuildTags } from '../utils/devopsApi';
import DeployEnvSubMap from '../utils/DeployEnvSubMap';
dotenv.config();

const credential = new InteractiveBrowserCredential({
  tenantId: 'c0b331af-5b04-49cd-874d-c8d1e0d0db1f',
  clientId: '299bf366-1934-45fa-b6a1-ff3c98c6e5fb',
});

async function getWebApps(deployEnv, forceRefresh) {
  //Get all resource groups for the given environment
  const resourceGroups = await getCachedAsync(
    `rgv2_${deployEnv}`,
    async () => await getResourceGroups(deployEnv),
    forceRefresh,
  );

  //Get all web apps for the given environment
  const rgWebAppCalls = [];
  for (const rg of resourceGroups) {
    rgWebAppCalls.push(getCachedAsync(
      `rgappsv2_${rg}`,
      async () => await getResourceGroupWebApps(rg, deployEnv),
      forceRefresh,
    ));
  }

  const rgWebAppLists = await Promise.all(rgWebAppCalls);

  const webApps = [];
  for (const rgWebApps of rgWebAppLists) {
    webApps.push(...rgWebApps);
  }

  return webApps;
}

async function getResourceGroups(deployEnv) {
  const resourceClient = new ResourceManagementClient(credential, DeployEnvSubMap[deployEnv]);
  const resourceGroups = [];
  for await (const item of resourceClient.resourceGroups.list()) {
    if (item.name.includes(deployEnv)) resourceGroups.push(item.name);
  }
  return resourceGroups;
}

async function getResourceGroupWebApps(resourceGroup, deployEnv) {
  const webSiteClient = new WebSiteManagementClient(credential, DeployEnvSubMap[deployEnv]);
  const webApps = [];
  for await (let item of webSiteClient.webApps.listByResourceGroup(resourceGroup)) {
    webApps.push({
      resourceGroup: resourceGroup,
      appName: item.name,
      id: item.id,
    });
  }
  return webApps;
}

async function getDeploymentStatus(webApp, deployEnv) {
  const webSiteClient = new WebSiteManagementClient(credential, DeployEnvSubMap[deployEnv]);

  //Get deployment details for a single web app
  for await (let item of webSiteClient.webApps.listDeployments(
    webApp.resourceGroup,
    webApp.appName,
  )) {
    if (item.active) {
      const message = JSON.parse(item.message);

      const tags = await getDeploymentBuildTags(message.buildId);

      const branch = (tags.artifactBranch ?? message.branch).replace('refs/heads/', '');
      const commitId = tags.artifactCommitId ?? message.commitId;

      return {
        appUrl: `https://portal.azure.com/#@opendorse.com/resource${webApp.id}`,
        name: webApp.appName,
        resourceGroup: webApp.resourceGroup,
        branch,
        ciBuildNumber: tags.artifactBuildNumber ?? 'N/A',
        ciBuildUrl: tags.artifactBuildId ? `${message.collectionUrl}${message.teamProjectName}/_build/results?buildId=${tags.artifactBuildId}` : undefined,
        deployBuildNumber: message.buildNumber,
        deployBuildUrl: `${message.collectionUrl}${message.teamProjectName}/_build/results?buildId=${message.buildId}`,
        commitUrl: `${message.collectionUrl}${message.teamProjectName}/_git/${message.repoName}/commit/${commitId}`,
        commitId: commitId.substr(0, 8),
        repository: message.repoName,
        author: tags.artifactAuthor ?? item.author,
        deployedDate: item.endTime,
        type: webApp.appName.includes('func')
          ? AppTypeEnum.func
          : AppTypeEnum.app,
      };
    }
  }
}

async function getDeploymentBuildTags(buildId) {
  const result = {};

  try {
    const buildTagsResponse = await getBuildTags(buildId);

    for (const tag of buildTagsResponse.value) {
      const tagParts = tag.split('=');
      if (tagParts.length > 1) {
        result[tagParts[0]] = tagParts[1];
      }
    }
  } catch (err) {
    // just eat the error as we'll fallback to the details from the deployment pipeline
    console.log(`no tags could be retrieved for build ${buildId}`, err);
  }

  return result;
}

export function* loadData({ payload }) {
  try {
    yield put(actions.loading(true));
    const webApps = yield call(getWebApps, payload.env, payload.forceRefresh);
    yield put(actions.loadWebAppsSuccess({ env: payload.env, data: webApps }));
    yield put(actions.loading(false));
    var dataCalls = [];
    for (const webApp of webApps) {
      dataCalls.push(getDeploymentStatus(webApp, payload.env));
    }
    try {
      for (const dataCall of dataCalls) {
        const data = yield call(async () => await dataCall);
        yield put(
          actions.loadSingleDataSuccess({ env: payload.env, webApp: data.name, data }),
        );
      }
    } catch {}
  } catch {}
  yield put(actions.loading(false));
  yield put(actions.loadingPanel({ env: payload.env, loading: false }));
}

export function* loadSingleData({ payload: { env, ...webApp } }) {
  try {
    yield put(
      actions.loadingPanel({ env, webApp: webApp.appName, loading: true }),
    );
    const data = yield call(getDeploymentStatus, webApp, env);
    yield put(
      actions.loadSingleDataSuccess({ env, webApp: webApp.appName, data }),
    );
  } catch {}
  yield put(
    actions.loadingPanel({ env, webApp: webApp.appName, loading: false }),
  );
}

export function* rootSaga() {
  yield all([takeLatest(actionTypes.LOAD_DATA, loadData)]);
  yield all([takeLatest(actionTypes.LOAD_SINGLE_DATA, loadSingleData)]);
}
