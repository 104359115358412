import styled, { css } from 'styled-components';
import opendorseMark from '../assets/mark-opendorse.svg';

export const AppWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const AppHeader = styled.div`
  padding: 32px;
  background-color: #edf0f2;
  box-shadow: 0 2px 9px rgba(19, 41, 63, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  > div:first-child {
    flex: 1;
  }
  ::after {
    flex: 1;
    content: '';
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px;
    ::after {
      content: none;
    }
  }
`;

export const AppLogo = styled.img`
  width: 200px;
  height: auto;
  box-sizing: border-box;
`;

export const PageContent = styled.div`
  margin: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  @media (max-width: 480px) {
    margin: 8px;
  }
`;

export const BodyHeading = styled.h1`
  font-size: 24px;
  font-family: 'Lato', 'arial', 'sans-serif';
  font-weight: 700;
  margin: 0;
  text-align: center;
  color: #13293f;
`;

export const BodySubheading = styled.h2`
  font-size: 18px;
  font-family: 'Lato', 'arial', 'sans-serif';
  font-weight: 300;
  margin: 4px 0 0 0;
  text-align: center;
  color: #13293f;
`;

export const ExtractorWrapper = styled.div`
  margin-top: 48px;
  > button {
    margin-left: 16px;
  }
`;

export const LoadingWrapper = styled.div`
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  z-index: 20;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  > div {
    margin: 2em auto;
    width: 40px;
    height: 40px;
    position: relative;
  }
`;

export const Branch = styled.div`
  font-size: 18px;
  font-weight: 700;
  font-family: 'Lato', 'arial', 'sans-serif';
  text-align: center;
`;

export const CommitUrl = styled.a`
  color: #007db3;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  font-family: 'Lato', 'arial', 'sans-serif';
  display: block;
  &:hover {
    color: #7fbdd9;
    text-decoration: underline;
  }
`;

const activeStyles = css`
  color: #007db3;
  &:hover {
    color: #7fbdd9;
    text-decoration: underline;
  }
`;
export const AppUrl = styled.a`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Lato', 'arial', 'sans-serif';
  margin: 0;
  color: #13293f;
  text-decoration: none;
  font-family: 'Lato', 'arial', 'sans-serif';
  ${(props) => (props.href ? activeStyles : null)};
`;

export const BuildUrl = styled.a`
  color: #007db3;
  text-decoration: none;
  &:hover {
    color: #7fbdd9;
    text-decoration: underline;
  }
`;

export const PanelsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

export const Panel = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 200px;
  height: 150px;
  border-radius: 4px;
  border: 1px solid #edf0f2;
  box-shadow: 0 2px 4px 0 rgba(19, 41, 63, 0.2);
  margin: 8px;
  @media (max-width: 480px) {
    width: calc(100% - 16px);
  }
`;

export const PanelImage = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 4px;
  opacity: 0.3;
  filter: grayscale(100%);
  background-position: top, center;
  background-size: cover, 65% 65%;
  background-repeat: no-repeat;
  background-image: url(${(props) => `"${props.image}"`}),
    url(${`"${opendorseMark}"`});
`;

export const PanelBody = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-image: linear-gradient(to bottom, transparent, #717f8c);
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: ${(props) => (props.active ? '#13293f' : '#ffffff')};
    font-size: 48px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }
  &:hover {
    h1 {
      color: #13293f;
    }
  }
`;

export const DetailsWrapper = styled.div`
  width: 100%;
  padding-top: 32px;
  margin-top: 16px;
  border-top: 2px solid #e4e7eb;
  @media (max-width: 480px) {
    margin-top: 0;
    padding-top: 16px;
  }
`;

export const DetailsHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  > img {
    height: 32px;
    width: 32px;
    cursor: pointer;
    margin: 0 0 -2px 8px;
  }
`;

export const DetailsPanelsWrapper = styled.div`
  gap: 16px;
  margin: 32px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 480px) {
    margin: 16px 0;
    gap: 8px;
    flex-direction: column;
  }
`;

export const DetailsPanel = styled.div`
  width: 350px;
  min-height: 181px;
  border-radius: 4px;
  border: 2px solid #e4e7eb;
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  justify-content: space-between;
  @media (max-width: 480px) {
    width: calc(100% - 32px);
    padding: 8px 12px;
    min-height: 139px;
  }
`;

export const DetailsPanelHeading = styled.div`
  display: flex;
  flex-direction: row;
  color: #13293f;
  justify-content: space-between;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    > img {
      height: 48px;
      width: 48px;
      margin-right: 16px;
      @media (max-width: 480px) {
        height: 32px;
        width: 32px;
        margin-right: 8px;
      }
    }
    > div {
      max-width: 264px;
    }
  }
  > img {
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin: -4px -10px 0 8px;
    @media (max-width: 480px) {
      margin: 0 -4px 0 4px;
    }
  }
  p {
    font-size: 12px;
    font-family: 'Lato', 'arial', 'sans-serif';
    margin-top: 4px;
  }
`;

export const DetailsBodyWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  &::before {
    content: '';
  }
  > div {
    > p {
      font-size: 12px;
      font-family: 'Lato', 'arial', 'sans-serif';
      &:first-child {
        margin-bottom: 4px;
      }
    }
    @media (max-width: 480px) {
      margin-top: 8px;
    }
  }
`;
