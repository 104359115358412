import React from 'react';
import PropTypes from 'prop-types';
import { StyledTextbox } from '../styles/component-styles';

export function Textbox({ value, placeholder, onChange, type }) {
  return (
    <StyledTextbox
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      type={type}
    />
  );
}

Textbox.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
};

export default Textbox;
