import styled from 'styled-components';

export const StyledButton = styled.button`
  color: #13293f;
  background-color: #91be3f;
  font-size: 18px;
  font-family: 'Lato', 'arial', 'sans-serif';
  font-weight: 600;
  border: none;
  border-radius: 32px;
  padding: 12px 32px;
  &:hover {
    cursor: pointer;
    background-color: #c8df9e;
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledTextbox = styled.input`
  display: block;
  margin-right: auto;
  margin-left: auto;
  outline: 1.5px solid #89949f;
  border: none;
  color: #13293f;
  font-size: 16px;
  font-family: 'Lato', 'arial', 'sans-serif';
  font-weight: 600;
  border-radius: 32px;
  padding: 12px 32px;
  margin-top: 20px;
  &:focus {
    outline-color: #91be3f;
  }
  &::placeholder {
    font-weight: 300;
    color: #89949f;
  }
`;
