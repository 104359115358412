const DeployEnvEnum = {
  dev1: 'dev1',
  dev2: 'dev2',
  qa1: 'qa01',
  qa2: 'qa02',
  stage: 'stg1',
  prod: 'prod',
};

export default DeployEnvEnum;
