import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/actions';
import AppTypeEnum from '../utils/appTypeEnum';
import appIcon from '../assets/icon-app.svg';
import functionIcon from '../assets/icon-function.svg';
import refreshIcon from '../assets/icon-refresh.svg';
import LoadingSpinner from '../components/loading-spinner';
import Textbox from '../components/textbox';
import {
  BodyHeading,
  DetailsHeadingWrapper,
  DetailsPanel,
  DetailsPanelHeading,
  DetailsPanelsWrapper,
  DetailsWrapper,
  DetailsBodyWrapper,
  Branch,
  BuildUrl,
  CommitUrl,
  AppUrl,
} from '../styles/app-styles';

export const BranchDetails = ({ env, data }) => {
  const [searchField, setSearchField] = useState('');
  const [filteredResources, setFilteredResources] = useState(data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data.length) dispatch(actions.loadData({ env, forceRefresh: false }));
  }, [dispatch, env, data]);

  useEffect(() => {
    setFilteredResources(
      data.filter((resource) =>
        resource.name.toLocaleLowerCase().includes(searchField)
      )
    );
  }, [data, searchField]);

  const dataLoading = data?.some((resource) => resource.loading);

  const onSearchChange = (event) => {
    setSearchField(event.target.value.toLocaleLowerCase());
  };

  return (
    <>
      <DetailsWrapper>
        <DetailsHeadingWrapper>
          <BodyHeading>{`${env.toUpperCase()} Environment Resources`}</BodyHeading>
          {!!data.length && !dataLoading && (
            <img
              alt="refresh"
              src={refreshIcon}
              onClick={() => dispatch(actions.loadData({ env, forceRefresh: true }))}
            />
          )}
        </DetailsHeadingWrapper>
        <Textbox
          onChange={onSearchChange}
          placeholder="Search resources..."
          value={searchField}
          type="search"
        />
        {!!data.length && (
          <DetailsPanelsWrapper>
            {filteredResources.map((resource) => (
              <DetailsPanel key={resource.name}>
                <DetailsPanelHeading>
                  <div>
                    <img
                      alt=""
                      src={
                        resource.type === AppTypeEnum.app
                          ? appIcon
                          : functionIcon
                      }
                    />
                    <div>
                      <AppUrl
                        href={resource.appUrl}
                        title={resource.name}
                        target="_blank"
                      >
                        {resource.name}
                      </AppUrl>
                      {!!resource.repository && <p>{resource.repository}</p>}
                    </div>
                  </div>
                  {!dataLoading && (
                    <img
                      alt="refresh"
                      src={refreshIcon}
                      onClick={() =>
                        dispatch(
                          actions.loadSingleData({
                            env,
                            appName: resource.name,
                            resourceGroup: resource.resourceGroup,
                          })
                        )
                      }
                    />
                  )}
                </DetailsPanelHeading>
                <DetailsBodyWrapper>
                  {resource.loading && <LoadingSpinner />}
                  {!!resource.repository && (
                    <>
                      <div>
                        <Branch>{resource.branch}</Branch>
                        <CommitUrl
                          href={resource.commitUrl}
                          target="_blank"
                          alt={resource.commitId}
                        >
                          {resource.commitId}
                        </CommitUrl>
                      </div>
                      <div>
                        <p>{`Author: ${resource.author}`}</p>
                        <p>
                          DevOps CI build:{' '}
                          <BuildUrl
                            href={resource.ciBuildUrl}
                            target="_blank"
                            inert={resource.ciBuildUrl ? null : ''}
                          >
                            {resource.ciBuildNumber}
                          </BuildUrl>
                        </p>
                        <p>
                          DevOps deployment:{' '}
                          <BuildUrl
                            href={resource.deployBuildUrl}
                            target="_blank"
                          >
                            {resource.deployBuildNumber}
                          </BuildUrl>
                        </p>
                        <p>{`Last updated: ${new Date(
                          resource.deployedDate
                        ).toLocaleString()}`}</p>
                      </div>
                    </>
                  )}
                </DetailsBodyWrapper>
              </DetailsPanel>
            ))}
          </DetailsPanelsWrapper>
        )}
      </DetailsWrapper>
    </>
  );
};

BranchDetails.propTypes = {
  env: PropTypes.string,
};

export default BranchDetails;
