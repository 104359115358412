import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import * as selectors from '../redux/selectors';
import DeployEnvEnum from '../utils/DeployEnvEnum';
import LoadingSpinner from '../components/loading-spinner';
import BranchDetails from './branch-details';
import {
  BodyHeading,
  PanelsWrapper,
  Panel,
  PanelImage,
  PanelBody,
} from '../styles/app-styles';

export const Home = ({ history }) => {
  const loading = useSelector(selectors.selectLoading());
  const dev1Data = useSelector(selectors.selectDev1Data());
  const dev2Data = useSelector(selectors.selectDev2Data());
  const qa1Data = useSelector(selectors.selectQa1Data());
  const qa2Data = useSelector(selectors.selectQa2Data());
  const stageData = useSelector(selectors.selectStageData());
  const prodData = useSelector(selectors.selectProdData());

  return (
    <>
      {loading && <LoadingSpinner fixed />}
      <PanelsWrapper>
        <Panel onClick={() => history.push('/dev1')}>
          <PanelImage />
          <PanelBody active={history.location.pathname === '/dev1'}>
            <BodyHeading>DEV 1</BodyHeading>
          </PanelBody>
        </Panel>
        <Panel onClick={() => history.push('/dev2')}>
          <PanelImage />
          <PanelBody active={history.location.pathname === '/dev2'}>
            <BodyHeading>DEV 2</BodyHeading>
          </PanelBody>
        </Panel>
        <Panel onClick={() => history.push('/qa1')}>
          <PanelImage />
          <PanelBody active={history.location.pathname === '/qa1'}>
            <BodyHeading>QA 1</BodyHeading>
          </PanelBody>
        </Panel>
        <Panel onClick={() => history.push('/qa2')}>
          <PanelImage />
          <PanelBody active={history.location.pathname === '/qa2'}>
            <BodyHeading>QA 2</BodyHeading>
          </PanelBody>
        </Panel>
        <Panel onClick={() => history.push('/stage')}>
          <PanelImage />
          <PanelBody active={history.location.pathname === '/stage'}>
            <BodyHeading>STAGE</BodyHeading>
          </PanelBody>
        </Panel>
        <Panel onClick={() => history.push('/prod')}>
          <PanelImage />
          <PanelBody active={history.location.pathname === '/prod'}>
            <BodyHeading>PROD</BodyHeading>
          </PanelBody>
        </Panel>
      </PanelsWrapper>
      <Switch>
        <Route
          exact
          path="/dev1"
          render={() => (
            <BranchDetails env={DeployEnvEnum.dev1} data={dev1Data} />
          )}
        />
        <Route
          exact
          path="/dev2"
          render={() => (
            <BranchDetails env={DeployEnvEnum.dev2} data={dev2Data} />
          )}
        />
        <Route
          exact
          path="/qa1"
          render={() => (
            <BranchDetails env={DeployEnvEnum.qa1} data={qa1Data} />
          )}
        />
        <Route
          exact
          path="/qa2"
          render={() => (
            <BranchDetails env={DeployEnvEnum.qa2} data={qa2Data} />
          )}
        />
        <Route
          exact
          path="/stage"
          render={() => (
            <BranchDetails env={DeployEnvEnum.stage} data={stageData} />
          )}
        />
        <Route
          exact
          path="/prod"
          render={() => (
            <BranchDetails env={DeployEnvEnum.prod} data={prodData} />
          )}
        />
      </Switch>
    </>
  );
};

export default Home;
