import { combineReducers } from 'redux';
import DeployEnvEnum from '../utils/DeployEnvEnum';
import AppTypeEnum from '../utils/appTypeEnum.js';
import { actionTypes } from './actions';

export const initialState = {
  loading: false,
  data: {
    [DeployEnvEnum.dev1]: [],
    [DeployEnvEnum.dev2]: [],
    [DeployEnvEnum.qa1]: [],
    [DeployEnvEnum.qa2]: [],
    [DeployEnvEnum.stage]: [],
    [DeployEnvEnum.prod]: [],
  },
};

export const rootReducer = combineReducers({
  loading: (state = initialState.loading, { type, loading }) => {
    switch (type) {
      case actionTypes.LOADING:
        return loading;
      default:
        return state;
    }
  },
  data: (state = initialState.data, { type, payload }) => {
    switch (type) {
      case actionTypes.LOAD_WEB_APPS_SUCCESS:
        return {
          ...state,
          [payload.env]: payload.data.map((webApp) => {
            return {
              loading: true,
              name: webApp.appName,
              type: webApp.appName.includes('func')
                ? AppTypeEnum.func
                : AppTypeEnum.app,
            };
          }),
        };
      case actionTypes.LOAD_SINGLE_DATA_SUCCESS:
        return {
          ...state,
          [payload.env]: state[payload.env].map((app) =>
            app.name === payload.data.name
              ? { ...payload.data, loading: false }
              : app,
          ),
        };
      case actionTypes.LOADING_PANEL:
        return {
          ...state,
          [payload.env]: state[payload.env].map((app) => {
            return !payload.webApp || payload.webApp === app.name
              ? { ...app, loading: payload.loading }
              : app;
          }),
        };
      default:
        return state;
    }
  },
});
