
export async function getCachedAsync(key, getDataAsync, forceRefresh) {
    if (forceRefresh) {
        localStorage.removeItem(key);
    }

    let cached = localStorage.getItem(key);

    if (cached === null) {
        let data = await getDataAsync();
        localStorage.setItem(key, JSON.stringify(data));
        return data;
    }

    return JSON.parse(cached);
}

export function clearCache() {
    localStorage.clear();
}