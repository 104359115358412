import { msalConfig, tokenRequest } from './devopsAuthConfig';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'

const devopsMsalInstance = new PublicClientApplication(msalConfig);

async function getToken() {
    const account = devopsMsalInstance.getActiveAccount();
    const request = {
        ...tokenRequest,
        account: account
    }
    try {
        const response = await devopsMsalInstance.acquireTokenSilent(request);
        return response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            console.warn('Silent token acquisition failed... acquiring token using popup');
            const popupResponse = await devopsMsalInstance.acquireTokenPopup(request);
            return popupResponse.accessToken;
        } else {
            console.error('Could not obtain token', error);
            throw error;
        }
    }
}

export async function getBuildTags(buildId) {
    const token = await getToken();

    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    const response = await fetch(`https://dev.azure.com/opendorse/Opendorse-Platform/_apis/build/builds/${buildId}/tags?api-version=5.1`, options);
    const content = await response.json();

    return content;
}
