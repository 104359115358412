import { createSelector } from 'reselect';
import DeployEnvEnum from '../utils/DeployEnvEnum';

const selector = (state) => state;

const selectLoading = () => createSelector(selector, ({ loading }) => loading);

const selectDev1Data = () =>
  createSelector(selector, ({ data }) => data[DeployEnvEnum.dev1]);

const selectDev2Data = () =>
  createSelector(selector, ({ data }) => data[DeployEnvEnum.dev2]);

const selectQa1Data = () =>
  createSelector(selector, ({ data }) => data[DeployEnvEnum.qa1]);

const selectQa2Data = () =>
  createSelector(selector, ({ data }) => data[DeployEnvEnum.qa2]);

const selectStageData = () =>
  createSelector(selector, ({ data }) => data[DeployEnvEnum.stage]);

const selectProdData = () =>
  createSelector(selector, ({ data }) => data[DeployEnvEnum.prod]);

export {
  selectLoading,
  selectDev1Data,
  selectDev2Data,
  selectQa1Data,
  selectQa2Data,
  selectStageData,
  selectProdData,
};
