export const msalConfig = {
    auth: {
        clientId: "299bf366-1934-45fa-b6a1-ff3c98c6e5fb", // This is the ONLY mandatory field that you need to supply.
        authority: "https://login.microsoftonline.com/c0b331af-5b04-49cd-874d-c8d1e0d0db1f", // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: "http://localhost:3000", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.href
        postLogoutRedirectUri: "http://localhost:3000", // Simply remove this line if you would like navigate to index page after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO btw tabs.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    }
};

export const tokenRequest = {
    scopes: ["499b84ac-1321-427f-aa17-267ca6975798/.default"], // 499b84ac-1321-427f-aa17-267ca6975798 is the App Id of Azure DevOps Service
    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};
