import { Switch, Route } from 'react-router-dom';
import { GlobalStyles } from './styles/global-styles';
import {
  AppWrapper,
  AppHeader,
  AppLogo,
  BodyHeading,
  BodySubheading,
  PageContent,
} from './styles/app-styles';
import OpendorseLogo from './assets/logo-opendorse.png';
import Home from './containers/home';
// import Button from './components/button';

function App() {
  return (
    <div id="app">
      <GlobalStyles />
      <AppWrapper>
        <AppHeader>
          <div><AppLogo src={OpendorseLogo} alt="Opendorse" /></div>
          <div>
            <BodyHeading>Deployment Dashboard</BodyHeading>
            <BodySubheading>
              Azure App Service Active Deployments
            </BodySubheading>
          </div>
          {/* <Button
            title="Log in"
            // onClick={() => dispatch(actions.Login())}
          /> */}
        </AppHeader>
        <PageContent>
          <Switch>
            <Route path="/" component={Home} />
            {/* <Route path="/login" component={Login} /> */}
          </Switch>
        </PageContent>
      </AppWrapper>
    </div>
  );
}

export default App;
