export const actionTypes = {
  LOADING: 'LOADING',
  LOADING_PANEL: 'LOADING_PANEL',
  LOAD_DATA: 'LOAD_DATA',
  LOAD_WEB_APPS_SUCCESS: 'LOAD_WEB_APPS_SUCCESS',
  LOAD_SINGLE_DATA: 'LOAD_SINGLE_DATA',
  LOAD_SINGLE_DATA_SUCCESS: 'LOAD_SINGLE_DATA_SUCCESS',
};

export const actions = {
  loading: (loading) => ({
    type: actionTypes.LOADING,
    loading,
  }),
  loadingPanel: (payload) => ({
    type: actionTypes.LOADING_PANEL,
    payload,
  }),
  loadData: (payload) => ({
    type: actionTypes.LOAD_DATA,
    payload,
  }),
  loadWebAppsSuccess: (payload) => ({
    type: actionTypes.LOAD_WEB_APPS_SUCCESS,
    payload,
  }),
  loadSingleData: (payload) => ({
    type: actionTypes.LOAD_SINGLE_DATA,
    payload,
  }),
  loadSingleDataSuccess: (payload) => ({
    type: actionTypes.LOAD_SINGLE_DATA_SUCCESS,
    payload,
  }),
};
