import DeployEnvEnum from './DeployEnvEnum';

const devTest = 'fc798404-6f2a-46a6-8af6-0c9b87b7fd8d';

const DeployEnvSubMap = {
  [DeployEnvEnum.dev1]: devTest,
  [DeployEnvEnum.dev2]: devTest,
  [DeployEnvEnum.qa1]: devTest,
  [DeployEnvEnum.qa2]: devTest,
  [DeployEnvEnum.stage]: '6efe936a-d593-4eba-bbff-1c54734e38cf',
  [DeployEnvEnum.prod]: '32535a9e-4953-4b01-a78a-b999df4d5f90',
};

export default DeployEnvSubMap;
